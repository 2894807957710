import { fetchStoreServices } from "@/apis/fetchData";

const state = () => ({
  portalInfo: null,
});

const getters = {
  getPortalInfo: (state) => state.portalInfo,
};

const mutations = {
  setPortalInfo(state, v) {
    state.portalInfo = v;
  },
};

const actions = {
  async init({ state, rootState, commit }) {
    if (state.portalInfo) return;

    try {
      const terminalId = rootState?.orderInfo?.paymentCode;
      const shopId = rootState.orderInfo?.shopId;
      console.log(`portal init: ${shopId} ${terminalId}`);
      const res = await getPortalInfo(shopId, terminalId);
      // const res = await dummyPortal();
      if (res.data) {
        console.log("getPortalResData: ", res.data);
        commit("setPortalInfo", res.data);

        // fetchStoreServices，改為撈取shop/portal/terminal setings
        setupPortal(res.data);
        const appId =
          res.data.sso && res.data.sso.appId ? res.data.sso.appId : null;
        commit("updateSSOAppId", appId, { root: true });
        commit("updateShopSettings", res.data, { root: true });
        if (res.data.points) {
          const pointsUniqueByKey = [
            ...new Map(
              res.data.points.map((item) => [item["pointCode"], item])
            ).values(),
          ];
          commit("updateSupportedPoints", pointsUniqueByKey, { root: true });
        } else {
          commit("updateSupportedPoints", [], { root: true });
        }
      }

      
    } catch (error) {
      console.log('getPortalInfo failed:', error);
      commit("setPortalInfo", null);
      commit("updateSupportedPoints", [], { root: true });
    }
    // return state.portalInfo;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

function getPortalInfo(shopId, terminalId) {
  if (!shopId) throw new Error("no shop id");
  return fetchStoreServices(shopId, terminalId);
  // let params = new URLSearchParams(window.location.search);
  // const portalKey = params.get('portal_key');
  // const shopId = params.get('shop_id');
  // if (!portalKey) throw new Error('no portal key');
  // let url = `${process.env.VUE_APP_API_HOST}/stores/v1/portals/${portalKey}`;
  // if (shopId) url += `?shop_id=${shopId}`;
  // let config = { url, method: 'GET' };
  // return qcsso.request(config);
}

function setupPortal(portalInfo) {
  // console.log("tilte:" + portalInfo?.portal?.title);
  // document.title = portalInfo?.portal?.title || "DDPAY";

  console.log("color:" + portalInfo?.portal?.color);
  const colors = portalInfo?.portal?.color || {};
  const root = document.documentElement;
  Object.entries(colors).forEach(([key, value]) => {
    if (!value) return;
    root.style.setProperty(key, value);
  });

  console.log("favicon:" + portalInfo?.portal?.icon);
  const iconLink = document.createElement("link");
  iconLink.type = "image/svg";
  iconLink.rel = "icon";
  iconLink.href = portalInfo?.portal?.favicon;
  document.head.appendChild(iconLink);
}

// function dummyPortal() {
//   let result = {
//     sso: {
//       appId: "jdowpvwaroqvrzb",
//     },
//     portal: {
//       logo: "https://storage.googleapis.com/gofoodie_assets/img/formosachang.png",
//       favicon:
//         "https://storage.googleapis.com/gofoodie_assets/img/fsmcfavicon.svg",
//       services: {
//         continueUrl:
//           "https://line.me/R/ti/p/@519imxds?from=page&accountId=519imxds",
//       },
//       banners: [
//         {
//           imageUrl:
//             "https://storage.googleapis.com/gofoodie_assets/img/fsmcbanner1.png",
//         },
//         {
//           imageUrl:
//             "https://storage.googleapis.com/gofoodie_assets/img/fsmcbanner2.png",
//         },
//       ],
//       
//       color: { "--k1": "#740008", "--q2": "#740008", "--q1": "#22798C" },
//       
//     },
//     points: [
//       {
//         pointCode: "8",
//         pointName: "台東金幣",
//         pointLogo: "",
//         pointStyle: {
//           background: "#E85189",
//         },
//         pointsToCurrency: {
//           points: 10,
//           currency: 1,
//         },
//         entityType: "Store",
//         entityId: "100",
//         storeId: 100,
//       },
//       {
//         pointCode: "1",
//         pointName: "紅利桃子",
//         pointLogo: "",
//         pointStyle: {
//           background: "#E85189",
//         },
//         pointsToCurrency: {
//           points: 1,
//           currency: 1,
//         },
//         entityType: "Store",
//         entityId: "100",
//         storeId: 100,
//       },
//     ],
//     excludePaymentMethods: [
//       // {
//       //   service: "linepay",
//       //   type: "linepay",
//       // },
//       // {
//       //   service: "cardpay",
//       //   type: "credit",
//       // },
//       {
//         service: "jko",
//         type: "jko",
//       },
//     ],
//   };

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({ data: result });
//     }, 300);
//   });
// }
