import Vue from "vue";
import Router from "vue-router";
import VueRouter from "vue-router";
import store from "@/store";
// import i18n from '@/i18n'

import { fetchOrderInfo, fetchOrderInfoByOrderId } from "@/apis/fetchData";

// import Home from '../views/Home.vue';
import AppLayout from "../components/Layout/AppLayout.vue";
// import Order from '../views/Order.vue';
// import Result from '../views/Result.vue';

// 解决vue-router在3.0版本以上重複點router報錯問題
const includPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return includPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/Error.vue"),
  },
  {
    path: "/",
    name: "",
    meta: {},
    redirect: () => {
      // the function receives the target route as the argument
      // a relative location doesn't start with `/`
      // or { path: 'profile'}
      return "Order";
    },
    components: {
      default: AppLayout,
    },
    children: [
      // {
      //   path: '/home/:token',
      //   name: 'Home',
      //   meta: {
      //     orderRequired: true  // 是否需要根據token取得訂單資訊，有token的都要檢查
      //   },
      //   component: Home
      // },
      // {
      //   path: '/welcome/:token',
      //   name: 'Welcome',
      //   meta: {
      //     orderRequired: false  // 是否需要根據token取得訂單資訊，有token的都要檢查
      //   },
      //   component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue')
      // },
      {
        // 因為sso logout 不支援動態參數
        // /orders?state=token -> /orders/token
        path: "/orders",
        name: "Orders",
        redirect: (to) => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          if (to.query.state) {
            return {
              path: "/orders/:token",
              params: { token: to.query.state },
              query: {},
            };
          } else {
            // return 'error';
            return { path: "/error", params: {}, query: { code: "OR002" } };
          }
        },
      },
      {
        path: "/orders/:token",
        name: "Order",
        meta: {
          orderRequired: true, // 是否需要根據token取得訂單資訊，有token的都要檢查
          redirect: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: Order
        component: () =>
          import(/* webpackChunkName: "order" */ "../views/Order.vue"),
      },
      {
        path: "/results",
        name: "Results",
        redirect: (to) => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          if (to.query.state) {
            return {
              path: "/result",
              params: {},
              query: { orderId: to.query.state },
            };
          } else {
            // return 'error';
            return { path: "/error", params: {}, query: { code: "OR003" } };
          }
        },
      },
      {
        path: "/result",
        name: "Result",
        meta: {
          orderRequired: false, // 是否需要根據token取得訂單資訊，有token的都要檢查
        },
        // component: Result
        component: () =>
          import(/* webpackChunkName: "result" */ "../views/Result.vue"),
      },
      {
        path: "/result/:orderId",
        name: "OrderResult",
        meta: {
          orderRequired: false, // 是否需要根據token取得訂單資訊，有token的都要檢查
        },
        // component: Result
        component: () =>
          import(/* webpackChunkName: "result" */ "../views/Result.vue"),
      },
      {
        path: "/payment/:token",
        name: "Payment",
        meta: {
          orderRequired: true, // 是否需要根據token取得訂單資訊，有token的都要檢查
          redirect: true,
        },
        component: () =>
          import(/* webpackChunkName: "payment" */ "../views/Payment.vue"),
      },
      {
        path: "/payment-list",
        name: "PaymentListRoot",
        meta: {
          orderRequired: false,
        },
        redirect: (to) => {
          if (to.query["terminal-id"] && to.query["terminal-id"].trim()) {
            return {
              path: `/payment-list/${to.query["terminal-id"].trim()}`,
              query: {}
            };
          } else {
            return { path: "/error", params: {}, query: { code: "TM001" } };
          }
        },
      },
      {
        path: "/payment-list/:terminalId",  // terminalId
        name: "PaymentList",
        meta: {
          orderRequired: false, 
        },
        component: () =>
          import(/* webpackChunkName: "payment" */ "../views/PaymentList.vue"),
      },

      {
        path: "/bind-member",
        name: "BindMember",
        meta: {
          orderRequired: false, // 是否需要根據token取得訂單資訊，有token的都要檢查
        },
        redirect: () => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          // 未來若要支援querystring模式，可以透過這個
          // if (to.query["hash"]) {
          //   return {
          //     path: `/bind-member/${to.query["hash"]}`,
          //     query: {}
          //   };
          // } else {
          //   return { path: "/error", params: {}, query: { code: "DDPW002" } };
          // }
          return { path: "/error", params: {}, query: { code: "DDPW002" } };
        },
      },
      {
        path: "/bind-member/:hash",
        name: "BindMemberHash",
        meta: {
          orderRequired: false, // 是否需要根據token取得訂單資訊，有token的都要檢查
        },
        component: () =>
          import(/* webpackChunkName: "result" */ "../views/BindMember.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// let locale = i18n.locale;
// const locales = Object.keys(i18n.messages);
// const supportedLanguages = [];
// for (const locale of locales) {
//   supportedLanguages.push(locale);
// }
router.beforeEach(async (to, from, next) => {
  // let lang = to.query.lang || locale;

  // if (!supportedLanguages.includes(lang)) {
  //   lang = locale;
  // }
  // // 如果 URL 有語系，則置換 i18n 語言
  // if (i18n.locale !== lang) {
  //   i18n.locale = lang
  //   localStorage.setItem('ddpw:locale', locale);
  // }
  store.commit("updateIsRouterResolved", false);
  // 進來前要先確認訂單資訊
  let previousToken =
    store.state.token ||
    from.params.token ||
    localStorage.getItem("ddpw:token");
  let order = store.state.orderInfo;
  let isOrderExisted = Object.keys(order).length;
  // console.log('from:', from);
  // console.log('to:', to)
  // console.log('token:', store.state.token, to.params.token, previousToken);
  if (previousToken !== to.params.token) {
    localStorage.removeItem("ddpw:amount");
  }

  if (to.meta.orderRequired) {
    // 需要取得訂單資訊，判斷redirect位置
    if (previousToken === to.params.token && isOrderExisted) {
      // token一樣，且訂單資訊己存在
      console.log("same token, and order exist");
      next();
    } else {
      // token不同者
      console.log("token changed：", previousToken, to.params.token);
      store.commit("updateToken", to.params.token);

      const amount = store.getters["getAmount"] || 0;
      // console.log('init storage amount:' + amount );

      try {
        const orderInfoRes = await fetchOrderInfo(to.params.token);
        console.log("fetchOrderInfo done:" + JSON.stringify(orderInfoRes));
        store.commit("updateOrderInfo", orderInfoRes.data);
        // 取得portal settings
        await store.dispatch("portal/init");

        // 確認是否有訂單金額
        if (orderInfoRes.data.orderInfo || amount > 0) {
          if (
            orderInfoRes.data.orderInfo &&
            orderInfoRes.data.orderInfo.amount
          ) {
            store.commit("updateAmount", orderInfoRes.data.orderInfo.amount);
          } else {
            store.commit("updateAmount", amount);
          }
          // next({name: "Payment", params:{token: to.params.token}});
          if (to.meta.redirect) {
            if (to.name === "Order") {
              next();
            } else {
              next({ name: "Order", params: { token: to.params.token } });
            }
          } else {
            next();
          }
        } else {
          console.log("order does not exist");
          store.commit("updateAmount", 0);
          if (to.meta.redirect) {
            next({ name: "Payment", params: { token: to.params.token } });
          } else {
            next();
          }
        }
      } catch (err) {
        console.error(err);
        next('/error');
      }
      // fetchOrderInfo(to.params.token)
      //   .then((res) => {
      //     console.log('fetchOrderInfo done:' + JSON.stringify(res));
      //     store.commit('updateOrderInfo', res.data);
      //     // 確認是否有訂單金額
      //     if (res.data.orderInfo || amount > 0) {
      //       if (res.data.orderInfo && res.data.orderInfo.amount) {
      //         store.commit('updateAmount', res.data.orderInfo.amount);
      //       } else {
      //         store.commit('updateAmount', amount);
      //       }
      //       // next({name: "Payment", params:{token: to.params.token}});
      //       if (to.meta.redirect) {
      //         if (to.name === 'Order') {
      //           next();
      //         } else {
      //           next({name: "Order", params:{token: to.params.token}});
      //         }

      //       } else {
      //         next();
      //       }
      //       // next(); // welcome page
      //     } else {
      //       console.log('order does not exist');
      //       store.commit('updateAmount', 0);
      //       if (to.meta.redirect) {
      //         next({name: "Payment", params:{token: to.params.token}});
      //       } else {
      //         next();
      //       }
      //     }

      //   })
      //   .catch((e) => {
      //     console.error('fetchOrderInfo failed', e.response);
      //     if (  e.response.data === 'Cannot get by token.') {
      //       next({
      //         path: '/error',
      //         query: { code: 'DDPW001'}
      //       });
      //     } else {
      //       next('/error');
      //     }

      //   });
    }
  } else if (
    (to.name === "Result" && to.query.orderId) ||
    ("OrderResult").includes(to.name)
  ) {
    // 確保有訂單編號，結果頁需要用orderId取得訂單資訊
    // 在component mounted時處理，CORS 問題
    try {
      const orderId = to.query.orderId || to.params.orderId;
      console.log(orderId);
      if (orderId) {
        const orderInfoByOrderIdRes = await fetchOrderInfoByOrderId(orderId);
        console.log('orderInfoByOrderIdRes: ' + JSON.stringify(orderInfoByOrderIdRes));
        store.commit("updateOrderInfo", orderInfoByOrderIdRes.data.context);

        // 取得portal settings
        await store.dispatch("portal/init");
      }
      next();

    } catch (err) {
      console.error(err);
      next();

    }
    // fetchOrderInfoByOrderId(orderId)
    //     .then((res) => {
    //       console.log('fetchOrderInfoByOrderId done', res);
    //       store.commit('updatePaidOrderInfo', res.data);
    //       store.commit('updateOrderInfo', res.data.context);
    //       next();
    //     })
    //     .catch((e) => {
    //       console.error('fetchOrderInfoByOrderId failed', e);
    //       // next('/error');
    //     });
  } else {
    // 不需取得訂單資訊者
    console.log("token for orderInfo is not necessary");
    next();
  }
});

router.afterEach(async () => {
  store.commit("updateIsRouterResolved", true);
});
// router.afterEach((to, from, next) => {
//   console.log('afterEach')
//   store.commit('updateIsRouterResolved', true);
// });
export default router;
